import styled from 'styled-components'
import {COLORS} from 'constants/colors'

export const Prompt = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
`

export const Instructions = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
`

export const DarkPrompt = styled(Prompt)`
  color: ${COLORS.font.darkBlue}
`

export const DarkInstructions = styled(Instructions)`
  color: ${COLORS.font.darkBlue}
`

export const Heading = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #333333;
`

export const BoldItalic = styled.span`
  font-weight: bold;
  font-style: italic;
`