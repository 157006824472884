import React, {Component, useCallback, useEffect, useRef, useState} from 'react'
import styled, {css, useTheme} from 'styled-components'
import i18next from "i18next";
import {getStoredLanguage, languagesCode, storeLanguage} from "services/languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/pro-solid-svg-icons";
import {useStateRef, useToggle} from "utils/hook";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
`

const ListText = styled(Text)`
  height: 48px;
  ${props => props.highlight && css`
    font-weight: bold;
  `}
`

const CurrentSelection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
`

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #ffffff;
  z-index: 100;
  padding: 11px 18px;
  align-items: flex-start;
  border: 1px solid #888888;
  position: absolute;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 5px;
`
export const LanguageSelector = () => {

  const [showList, toggleShowList] = useToggle(false);
  const [currentLang, setCurrentLang] = useState("");

  const [listBoxRef, setListBoxRef] = useStateRef();

  const onLangClick = async (lang) => {
    setCurrentLang(lang);
    toggleShowList();
    storeLanguage(lang)
    await i18next.changeLanguage(lang)
  }

  useEffect(() => {
    setCurrentLang(i18next.language)
  }, []);

  useEffect(() => {
    if (!listBoxRef) {
      return;
    }
    const top = listBoxRef.getBoundingClientRect().y + 30;
    listBoxRef.style.top = `${top}px`;
  }, [listBoxRef])

  return (
    <Layout>
      <CurrentSelection onClick={toggleShowList}>
        <Text color={"#000"}>{currentLang}</Text>
        <Icon icon={faCaretDown}/>
      </CurrentSelection>
      {showList &&
        <ListBox ref={setListBoxRef}>
          {languagesCode.map((lang, i) => (
            <ListText key={`key${i}`} onClick={() => onLangClick(lang)} highlight={lang === currentLang}>
              {lang}
            </ListText>
          ))}
        </ListBox>
      }
    </Layout>
  );
}


