import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import QrScanner from 'qr-scanner'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import guideboxSrc from 'assets/guidebox.svg'
import {useTranslation} from "react-i18next";

const ScannerOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
`

const CameraBox = styled.video`
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
`

const OverlayDiv = styled.div`
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CloseScannerButton = styled.button`
  padding: 0px 16px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 2rem;
  position: absolute;
  left: 0;
`

const Header = styled.div`
  font-size: 1.5rem;
`

const Guidebox = styled.img`
  max-width: 70vw;
  max-height: 70vh;
`

export const Scanner = ({onDataScanned, handleClose}) => {
  const videoRef = useRef()
  const scanner = useRef(null)
  const { t } = useTranslation();

  useEffect(() => {
    scanner.current = new QrScanner(videoRef.current, onData)
    scanner.current.start()
    return () => {
      scanner.current.stop()
    }
  }, [])

  const onData = (data) => {
    scanner && scanner.current.stop()
    const [label, id] = data.split('=')
    onDataScanned(label, id)
  }

  return (
    <>
      <CameraBox ref={videoRef}></CameraBox>
      <ScannerOverlay>
        <OverlayDiv>
          <CloseScannerButton onClick={handleClose}>
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth/>
          </CloseScannerButton>
          <Header>{t('ScanCode')}</Header>
        </OverlayDiv>
        <Guidebox src={guideboxSrc} />
        <OverlayDiv>{t('MoveCamera')} {t('EnsureQrCodeFrame')}</OverlayDiv>
      </ScannerOverlay>
    </>
  )
}
