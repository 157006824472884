import {Aether, createMass, defineReaction} from '@forrestertm/newton'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'

export const initGlobalMasses = () => {
  createMass(GLOBAL_MASS.locationMap)
  createMass(GLOBAL_MASS.restFailure, null)
  createMass(GLOBAL_MASS.selectedActions, {})

  defineReaction((orgMatter) => {
    const locationMap = new Map()
    let path = []
    function dfs(node) {
      path.push(node.name)
      if (node.children) {
        for (let ch of node.children) {
          dfs(ch)
        }
      }
      else {
        locationMap.set(node.locationId, [...path])
      }
      path.pop();
    }
    dfs(orgMatter.trees[0])
    Aether.massAction(GLOBAL_MASS.locationMap, locationMap)
  }, REST_MASS.organizations)
}