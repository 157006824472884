export const languages = {
  fr: 'Français',
  en: 'English',
  es: 'Español'
}

export const languagesCode = Object.keys(languages);

const LANGUAGE_KEY = 'currl'

export const storeLanguage = (langCode) => {
  localStorage.setItem(LANGUAGE_KEY, langCode);
}

export const getStoredLanguage = () => {
  return localStorage.getItem(LANGUAGE_KEY);
}
