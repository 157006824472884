import styled from 'styled-components'
import {COLORS} from 'constants/colors'

export const Button = styled.button`
  box-sizing: border-box;
  padding: 14px 28px;
  min-width: 116px;
  border-style: none;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-weight: bold;
  &:active {
    box-shadow: inset 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #ffffff;
  }
`

export const DefaultButton = styled(Button)`
  color: ${COLORS.font.darkBlue};
  &:active {
    background-color: ${COLORS.button.defaultActive};
  }
`
export const LightButton = styled(Button)`
  background-color: ${COLORS.button.light};
  color: ${COLORS.font.darkBlue};
  &:active {
    background-color: ${COLORS.button.lightActive};
  }
  &:disabled {
    color: ${COLORS.font.disabled}
  }
`
