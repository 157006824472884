import React from 'react'
import {Navigate, Redirect, Route, useLocation} from 'react-router-dom'
import {Aether} from '@forrestertm/newton'
import {REST_MASS} from 'constants/mass-names'

export const PrivateRoute = ({children, ...rest}) => {
  const {pathname, search} = useLocation()
  if (!Aether.matterOf(REST_MASS.auth).authToken) {
    return (
      <Navigate to={`/auth?from=${pathname}${search}`}/>
    )
  }

  return children
}
