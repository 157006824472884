export const COLORS = {
  font: {
    lightBlue: '#4a90e2',
    darkBlue: '#000f52',
    disabled: '#000f5277'
  },
  button: {
    default: '#ffffff',
    defaultActive: '#005194',
    light: 'rgba(15, 115, 238, 0.3)',
    lightActive: 'rgba(15, 115, 238, 1)'
  },
  background: {
    lightBlue: 'rgba(1, 119, 217, 1)',
    babyBlue: 'rgba(1, 119, 217, 0.2)'
  },
  checkbox: 'rgb(15, 115, 238)',
}

export const BLUE_GRADIENT = {
  blue1: '#5cb4f3',
  blue2: '#3172c2',
  blue3: '#1547a2',
  blue4: '#01298b'
}