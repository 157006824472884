import React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {FullLayout, VerticalSpacer} from 'components/layouts'
import {TopLogo} from 'components/top-logo'
import {DarkInstructions, DarkPrompt} from 'components/text'
import {DefaultButton} from 'components/button'
import {ArchContent} from 'components/arch'
import {COLORS} from 'constants/colors'
import {useTranslation} from "react-i18next";

export const OopsScreen = props => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const prevLocationId = search.get('prev')
  const { t } = useTranslation();

  const returnToActions = () => {
    navigate(`/actions?locationId=${prevLocationId}`)
  }

  return (
    <FullLayout align='center'>
      <TopLogo />
      <ArchContent align='center' background={[COLORS.background.babyBlue]} fontColor={COLORS.font.darkBlue} >
        <VerticalSpacer>
          <DarkPrompt>{t('Oops')}</DarkPrompt>
        </VerticalSpacer>
        <VerticalSpacer>
          <DarkInstructions>{t('SaveActions')}</DarkInstructions>
        </VerticalSpacer>
        <VerticalSpacer>
          <DefaultButton onClick={returnToActions}>{t('Back')}</DefaultButton>
        </VerticalSpacer>
      </ArchContent>
    </FullLayout>
  )
}
