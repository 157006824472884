import axios from 'axios'
import {Aether, createMass, defineReaction} from '@forrestertm/newton'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'

const LOCAL_KEY = 'caer'

export const initRestService = () => {
  createMass(REST_MASS.organizations)
  createMass(REST_MASS.actions)
  createMass(REST_MASS.auth)

  const cachedTokens = localStorage.getItem(LOCAL_KEY)
  if (cachedTokens) {
    const tokens = JSON.parse(cachedTokens)
    Aether.massAction(REST_MASS.auth, tokens)      // use an action to set the tokens so the reaction fires
  }
  else {
    window.location.assign('/#/auth')
  }
}

export const authSL = async (username, password) => {
  const callOptions = {
    method: 'POST',
    path: 'login',
    data: {username, password}
  }
  const transform = (data, status) => {
    localStorage.setItem(LOCAL_KEY, JSON.stringify(data))
    return data
  }
  restCall(REST_MASS.auth, callOptions, transform)
}

export const deviceAuthSL = async (authKey) => {
  const callOptions = {
    method: 'POST',
    path: 'public/auth/device',
    data: {authKey}
  }
  const transform = (data, status) => {
    const payloadBase64 = data.activation.split('.')[1]
    const payloadJSON = JSON.parse(window.atob(payloadBase64))
    const tokens = {
      authToken: data.activation,
      clientId: payloadJSON.clientId
    }
    localStorage.setItem(LOCAL_KEY, JSON.stringify(tokens))
    return tokens
  }
  restCall(REST_MASS.auth, callOptions, transform)
}

export const getOrganizations = async (clientId) => {
  const callOptions = {
    method: 'GET',
    path: `orgs/tree/client/${clientId}`
  }
  restCall(REST_MASS.organizations, callOptions)
}

export const getActions = async (locationId) => {
  const callOptions = {
    method: 'GET',
    path: `activities/location/${locationId}`
  }
  restCall(REST_MASS.actions, callOptions, data => data.activities)
}

export const saveActions = async (actions) => {
  const callOptions = {
    method: 'POST',
    path: 'actions/',
    data: {actions}
  }
  restCall(null, callOptions)
}

const restCall = async (resultsMass, callOptions, transform, noRefresh) => {
  const {authToken, refreshToken} = Aether.matterOf(REST_MASS.auth)

  let headers = {}
  if (!!authToken) {
    headers['Authorization'] = `Bearer ${authToken}`
  }
  if (callOptions.method === 'POST') {
    headers['Content-Type'] = 'application/json'
  }

  let options = {
    method: callOptions.method || 'GET',
    headers: headers,
    url: (callOptions.authCall ? process.env.AUTH_SERVICE_URL : process.env.SERVICE_URL) + callOptions.path,
    params: callOptions.params,
    data: callOptions.data
  }

  let errorStatus = null
  let errorMessage = null
  try {
    resultsMass && Aether.pendingAction(resultsMass)
    let response = await axios(options)
    const matter = !!transform ? transform(response.data, response.status) : response.data
    resultsMass && Aether.massAction(resultsMass, matter)
    return matter
  }
  catch (error) {
    // 404 errors have no response
    if (!!error.response) {
      if (error.response.status === 401) {
        Aether.resetMass(REST_MASS.auth)
        errorStatus = 401
      }
      else {
        errorStatus = error.response.status
      }
      errorMessage = error.response.data
    }
    else {
      errorStatus = undefined
      errorMessage = error.message
    }
  }

  Aether.massAction(GLOBAL_MASS.restFailure, {status: errorStatus, message: errorMessage})

  return {}
}