import React from 'react';
import logoSrc from 'assets/feedbacknow-logo-white.svg';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  width: 100%;
`

const Logo = styled.img`
  height: 16px;
  width: 241px;
`

export const Footer = () => {
  return (
    <LogoWrapper>
      <Logo src={logoSrc}/>
    </LogoWrapper>
  );
}
