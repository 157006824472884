import React, {useEffect} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {HashRouter as Router, Route, Routes} from 'react-router-dom'
import {Aether, useMass} from '@forrestertm/newton'
import {initServices} from 'services/init-services'
import {AuthScreen} from 'screens/auth-screen'
import {ActionsScreen} from 'screens/actions-screen'
import {ScanLocationScreen} from 'screens/scan-location-screen'
import {ThankYouScreen} from 'screens/thank-you-screen'
import {OopsScreen} from 'screens/oops-screen'
import {ExitScreen} from 'screens/exit-screen'
import {InvalidQRScreen, ServerErrorScreen} from 'screens/error-screen'
import {PrivateRoute} from 'components/auth/private-route'
import {GLOBAL_MASS} from 'constants/mass-names'

initServices()

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
    font-family: Lato, Helvetica, sans-serif;
  }
`


export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const App = (props) => {
  const restFailure = useMass(GLOBAL_MASS.restFailure)

  useEffect(() => {
    if (!!restFailure) {
      let destination
      switch (restFailure.status) {
        case 401:
          destination = '/#/auth'
          break
        default:
          destination = '/#/servererror'
          break
      }
      Aether.massAction(GLOBAL_MASS.restFailure, null)
      window.location.href = destination
    }
  }, [restFailure])

  return (
    <>
      <GlobalStyles/>
      <Content>
        <Router>
          <Routes>
            <Route path='/auth' element={<AuthScreen/>}/>
            <Route path='/scanLocation' element={<PrivateRoute><ScanLocationScreen/></PrivateRoute>}/>
            <Route path='/thankyou' element={<PrivateRoute><ThankYouScreen/></PrivateRoute>}/>
            <Route path='/actions' element={<PrivateRoute><ActionsScreen/></PrivateRoute>}/>
            <Route path='/oops' element={<PrivateRoute><OopsScreen/></PrivateRoute>}/>
            <Route path='/exit' element={<PrivateRoute><ExitScreen/></PrivateRoute>}/>
            <Route path='/invalidqr' element={<InvalidQRScreen/>}/>
            <Route path='/servererror' element={<ServerErrorScreen/>}/>
          </Routes>
        </Router>
      </Content>
    </>
  )
}
