export const enTranslations = {
  Welcome: "Welcome.",
  AuthQrCodeDevice: "Please scan the <0 /><1>authorization</1> QR code for this device",
  Illustration: "This is an illustration to show",
  ScannedQrCode: "you've scanned the QR code",
  MoveCamera: "Move the camera back and forth slowly and",
  EnsureQrCodeFrame: "ensure the entire QR code is in the frame",
  Go: "Let's go.",
  ScanLocation: "Please scan the <1>location</1> QR code <0/>to get started.",
  MyActions: "My Actions",
  Cancel: "Cancel",
  Complete: "Complete",
  Finish: "Finish",
  ThankYou: "Thank you.",
  ScanNextLocation: "Please scan the next <0/> <1>location</1> QR code",
  ScanCode: "Scan QR Code",
  Exit: "Exit.",
  AreYouSure: "Are you sure you want to",
  ExitLocation: "exit out of this Location?",
  Yes: "Yes",
  No: "No",
  Uhoh: "Uh oh!",
  InvalidQrCode: "This is an invalid QR code",
  TryScanAgain: "Please try to scan again",
  RescanCode: "Rescan QR code",
  Sorry: "Sorry!",
  NoConnection: "No internet connectivity detected",
  Reconnect: "Please reconnect and try again",
  Retry: "Retry",
  Error: "Error",
  ServerError: "The server encountered an error.",
  TryAgain: "Please try again later",
  UnableConnectFBN: "Unable to connect to FeedbackNow.",
  Ok: "Ok",
  Oops: "Oops...",
  SaveActions: "I want to save my Actions.",
  Back: "Back"
}
