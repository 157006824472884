import React from 'react'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquareCheck} from '@fortawesome/pro-solid-svg-icons'
import {faSquare} from '@fortawesome/free-regular-svg-icons'
import {COLORS} from 'constants/colors'

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => !props.disabled ? COLORS.checkbox : '#999999'};
  ${props => !props.disabled && css`
    cursor: pointer;
  `}
`

export const Checkbox = (props) => (
  <Icon
    icon={props.checked ? faSquareCheck : faSquare}
    checked={props.checked}
    disabled={props.disabled}
    onClick={(event) => props.onChange(!props.checked, event)}
  />
)
