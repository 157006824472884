import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {FullLayout, VerticalSpacer} from 'components/layouts'
import {TopLogo} from 'components/top-logo'
import {Instructions, Prompt, BoldItalic} from 'components/text'
import {DefaultButton} from 'components/button'
import {Scanner} from 'components/scanner'
import {ActionsLogoLight} from 'components/actions-logo'
import {BLUE_GRADIENT} from 'constants/colors'
import {ArchContent} from 'components/arch'
import {Trans, useTranslation} from "react-i18next";


export const ThankYouScreen = () => {
  const navigate = useNavigate()
  const [scanningActive, setScanningActive] = useState(false)
  const { t } = useTranslation();

  const onDataScanned = (label, data) => {
    setScanningActive(false)
    if (label !== 'locationId') {
      navigate('/invalidqr')
      return
    }
    navigate(`/actions?locationId=${data}`)
  }

  return (
    <FullLayout align='center'>
      {!scanningActive &&
      <>
        <TopLogo />
        <ArchContent align='center' background={[BLUE_GRADIENT.blue1, BLUE_GRADIENT.blue2, BLUE_GRADIENT.blue3, BLUE_GRADIENT.blue4]}>
          <VerticalSpacer>
            <ActionsLogoLight height={40} />
          </VerticalSpacer>
          <VerticalSpacer>
            <Prompt>{t('ThankYou')}</Prompt>
          </VerticalSpacer>
          <VerticalSpacer>
            <Instructions>
              <Trans i18nKey={'ScanNextLocation'} components={[<br />, <BoldItalic />]} />
            </Instructions>
          </VerticalSpacer>
          <VerticalSpacer>
            <DefaultButton onClick={() => setScanningActive(true)}>{t('ScanCode')}</DefaultButton>
          </VerticalSpacer>
        </ArchContent>
      </>
      }
      {scanningActive &&
        <Scanner onDataScanned={onDataScanned} handleClose={() => setScanningActive(false)}/>
      }
    </FullLayout>
  )
}
