import React from 'react'
import {useNavigate} from 'react-router-dom'
import {CenteredLayout, FullLayout, VerticalSpacer} from 'components/layouts'
import {DarkInstructions, DarkPrompt} from 'components/text'
import {LightButton} from 'components/button'
import {useTranslation} from "react-i18next";

const ErrorScreen = ({prompt, errorMessage, retryMessage, buttonText}) => {
  const navigate = useNavigate()
  return (
    <FullLayout align='center'>
      <CenteredLayout>
        <VerticalSpacer>
          <DarkPrompt>{prompt}</DarkPrompt>
        </VerticalSpacer>
        <VerticalSpacer>

          <DarkInstructions>{errorMessage}</DarkInstructions>
          <DarkInstructions>{retryMessage}</DarkInstructions>
        </VerticalSpacer>
        <VerticalSpacer>
          <LightButton onClick={() => navigate('/scanLocation')}>{buttonText}</LightButton>
        </VerticalSpacer>
      </CenteredLayout>
    </FullLayout>
  )
}

export const InvalidQRScreen = () => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      prompt={t('Uhoh')}
      errorMessage={t('InvalidQrCode')}
      retryMessage={t('TryScanAgain')}
      buttonText={t('RescanCode')}
    />
  )
};


export const ServerErrorScreen = () => {
  const { t } = useTranslation()
  return (
    <ErrorScreen
      prompt={t('Sorry')}
      errorMessage={t('UnableConnectFBN')}
      retryMessage={t('TryAgain')}
      buttonText={t('Ok')}
    />
  )
}
