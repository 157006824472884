export const REST_MASS = {
  organizations: 'organizations',
  actions: 'actions',
  auth: 'auth'
}

export const GLOBAL_MASS = {
  restFailure: 'restFailure',
  locationMap: 'locationMap',
  selectedActions: 'selectedActions'
}
