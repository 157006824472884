import React, {useState} from 'react'
import {isReady, useMass} from '@forrestertm/newton'
import styled from 'styled-components'
import {Navigate, useNavigate} from 'react-router-dom'
import {REST_MASS} from 'constants/mass-names'
import {deviceAuthSL} from 'services/rest-service'
import {FullLayout, VerticalSpacer} from 'components/layouts'
import {TopLogo} from 'components/top-logo'
import {Instructions, Prompt, BoldItalic} from 'components/text'
import {DefaultButton} from 'components/button'
import {Scanner} from 'components/scanner'
import actionsIconSrc from 'assets/actions-icon.svg'
import {COLORS} from 'constants/colors'
import {ArchContent} from 'components/arch'
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

export const AuthScreen = (props) => {
  const navigate = useNavigate()
  const mAuth = useMass(REST_MASS.auth)
  const [scanningActive, setScanningActive] = useState(false)
  const { t } = useTranslation();

  if (isReady(mAuth)) {
    return (
      <Navigate to={'/scanLocation'} />
    )
  }

  const onDataScanned = (label, data) => {
    setScanningActive(false)
    if (label !== 'auth') {
      navigate('/invalidqr')
      return
    }

    deviceAuthSL(data)
  }

  return (
    <FullLayout align='center'>
      {!scanningActive &&
      <>
        <TopLogo logoSrc={actionsIconSrc}/>
        <ArchContent align='center' background={[COLORS.background.lightBlue]}>
          <VerticalSpacer>
            <Prompt>{t("Welcome")}</Prompt>
          </VerticalSpacer>
          <VerticalSpacer>
            <Instructions>
              <Trans i18nKey={'AuthQrCodeDevice'} components={[<br />, <BoldItalic />]} />
            </Instructions>
          </VerticalSpacer>
          <VerticalSpacer>
            <DefaultButton onClick={() => setScanningActive(true)}>{t('ScanCode')}</DefaultButton>
          </VerticalSpacer>
        </ArchContent>
      </>
      }
      {scanningActive &&
        <Scanner onDataScanned={onDataScanned} handleClose={() => setScanningActive(false)}/>
      }
    </FullLayout>
  )
}