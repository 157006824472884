import {initReactI18next} from "react-i18next";
import i18next from "i18next";
import {enTranslations} from "./locales/en/translations";
import {esTranslations} from "./locales/es/translations";
import {frTranslations} from "./locales/fr/translations";
import {getStoredLanguage} from "./src/services/languages";

const [navigatorLanguage] = navigator.language.split('-');

i18next
  .use(initReactI18next) // passes locales down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      'en': {translation: enTranslations},
      'es': {translation: esTranslations},
      'fr': {translation: frTranslations}
    },
    lng: getStoredLanguage() ?? navigatorLanguage,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });