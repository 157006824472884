import styled from 'styled-components'
import actionsIconSrc from 'assets/actions-icon.svg'
import actionsIconLightSrc from 'assets/actions-icon-light.svg'

const Logo = styled.img`
  height: ${props => props.height}px;
`

const ActionsLogo = ({imgSrc, height}) => {
  return <Logo src={imgSrc} height={height} />
}

export const ActionsLogoLight = ({height}) => {
  return <ActionsLogo imgSrc={actionsIconLightSrc} height={height}/>
}

export const ActionsLogoDark = ({height}) => {
  return <ActionsLogo imgSrc={actionsIconSrc} height={height}/>
}