export const esTranslations = {
  Welcome: "Bienvenido.",
  AuthQrCodeDevice: "Escanee el código QR <0/> de <1>autorización</1> de este dispositivo",
  Illustration: "Esta es una ilustración para indicar",
  ScannedQrCode: "que escaneó el código QR",
  MoveCamera: "Mueva la cámara hacia delante y atrás con lentitud y",
  EnsureQrCodeFrame: "asegúrese de enmarcar todo el código QR",
  Go: "Vamos.",
  ScanLocation: "Escanee el código QR de <1>ubicación</1><0/>para comenzar.",
  MyActions: "Mis acciones",
  Cancel: "Cancelar",
  Complete: "Completar",
  Finish: "Finalizar",
  ThankYou: "Gracias.",
  ScanNextLocation: "Escanee el siguiente <0/> código QR de <1>ubicación</1>",
  ScanCode: "Escanear código QR",
  Exit: "Salir.",
  AreYouSure: "¿Está seguro de que desea",
  ExitLocation: "salir de esta ubicación?",
  Yes: "Sí",
  No: "No",
  Uhoh: "¡Vaya!",
  InvalidQrCode: "Es un código QR no válido",
  TryScanAgain: "Intente escanearlo nuevamente",
  RescanCode: "Volver a escanear código QR",
  Sorry: "¡Lo sentimos!",
  NoConnection: "No se detectó conectividad a Internet",
  Reconnect: "Vuelva a conectarse e inténtelo de nuevo",
  Retry: "Reintentar",
  Error: "Error…",
  ServerError: "Se produjo un error en el servidor.",
  TryAgain: "Inténtelo de nuevo más tarde",
  UnableConnectFBN: "No se puede conectar a FeedbackNow.",
  Ok: "Aceptar",
  Oops: "Ay...",
  SaveActions: "Me gustaría guardar mis Acciones.",
  Back: "Volver"
}
