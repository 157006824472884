import styled from 'styled-components'
import {COLORS} from 'constants/colors'
import {Header} from "components/auth/header";
import { Footer } from 'components/Footer';


export const CenteredLayout = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const HorizontalSpacer = styled.div`
  &:not(:last-child) {
    margin-right: ${props => props.margin || 20}px;
  }
`

export const VerticalSpacer = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: ${props => props.marginBottom || 0}px;
  }
`

export const FullLayoutWrapper = styled.div`
  height: ${window.innerHeight}px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'flex-start'};
  background-color: ${props => props.backgroundColor || '#ffffff'};
  user-select: none;
`

export const FullLayout = ({children, showLogo = false}) => {
  return (
    <FullLayoutWrapper>
      <Header withPadding showLogo={showLogo}/>
      {children}
      <Footer />
    </FullLayoutWrapper>
  )
}
