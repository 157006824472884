import React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Aether, useMass} from '@forrestertm/newton'
import {REST_MASS} from 'constants/mass-names'
import {FullLayout, VerticalSpacer} from 'components/layouts'
import {TopLogo} from 'components/top-logo'
import {DarkInstructions, DarkPrompt} from 'components/text'
import {DefaultButton} from 'components/button'
import {ArchContent} from 'components/arch'
import {COLORS} from 'constants/colors'
import {useTranslation} from "react-i18next";

export const ExitScreen = props => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const prevLocationId = search.get('prev')
  const { t } = useTranslation();

  const returnToActions = () => {
    Aether.massAction(REST_MASS.actions, null)
    navigate(`/actions?locationId=${prevLocationId}`)
  }

  const returnToScanLocation = () => {
    navigate(`/scanLocation`)
  }

  return (
    <FullLayout align='center'>
      <TopLogo />
      <ArchContent align='center' background={[COLORS.background.babyBlue]} fontColor={COLORS.font.darkBlue} >
        <VerticalSpacer>
          <DarkPrompt>{t('Exit')}</DarkPrompt>
        </VerticalSpacer>
        <VerticalSpacer>
          <DarkInstructions>
            {t('AreYouSure')}<br/>
            {t('ExitLocation')}
          </DarkInstructions>
        </VerticalSpacer>
        <VerticalSpacer>
          <DefaultButton onClick={returnToScanLocation}>{t('Yes')}</DefaultButton><br/>
        </VerticalSpacer>
        <VerticalSpacer>
          <DefaultButton onClick={returnToActions}>{t('No')}</DefaultButton>
        </VerticalSpacer>
      </ArchContent>
    </FullLayout>
  )
}
