import React from 'react';

import styled, {css} from "styled-components";
import {LanguageSelector} from "components/language-selector";
import { useLogo } from 'utils/hook';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  ${props => props.withPadding && css `padding: 20px 20px 0 20px;`}
`

const ClientLogo = styled.img`
  height: 25px;
`

const Column = styled.div`
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
`


export const Header = ({showLogo = false, withPadding = false}) => {
  const clientLogoSrc = useLogo();

  return (
    <HeaderWrapper withPadding={withPadding}>
      <Column>
        {showLogo && <ClientLogo src={clientLogoSrc}/>}
      </Column>
      <Column>
        <LanguageSelector/>
      </Column>
    </HeaderWrapper>
  );
}
