import {useCallback, useState} from "react";
import nypLogoSrc from "assets/client-logo.png";
import demoHQLogoSrc from "assets/demo-hq-logo.svg";
import actionsIconSrc from 'assets/actions-icon.svg'
import aegLogoSrc from 'assets/aeg-logo.png'
import {useMass} from '@forrestertm/newton'
import {REST_MASS} from "constants/mass-names";

/**
 *
 * @param initialValue
 * @returns {[boolean, () => void]}
 */
export const useToggle = (initialValue = false) => {

  const [boolValue, setBoolValue] = useState(initialValue);
  const toggleValue = useCallback(() => setBoolValue(v => !v), []);

  return [boolValue, toggleValue]
}

/**
 * Allows us to use a ref as a regular state and apply useEffect over it in components that subscribe.
 * @returns {[HTMLElement,((function(*): void)|*)]}
 */
export const useStateRef = () => {
  const [node, setNode] = useState(null);
  const setRef = useCallback(newNode => {
    setNode(newNode);
  }, []);
  return [node, setRef];
}

export const useLogo = () => {

  const auth = useMass(REST_MASS.auth);
  const prodLogoMap = {
    'HSlZWX5xx2n9e4kL8LQLW': demoHQLogoSrc,
    'XcvcJPhXeCAR6T4hdu_UL': nypLogoSrc,
    'SkfyfxxC0T0XjCqM8VIs7': aegLogoSrc,
  };
  let clientLogoSrc = actionsIconSrc;

  if (auth) {
    const { clientId } = auth;
    if (clientId in prodLogoMap) clientLogoSrc = prodLogoMap[clientId];
  }
  return clientLogoSrc;
};
