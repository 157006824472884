import styled, {css} from 'styled-components'
import {COLORS, BLUE_GRADIENT} from 'constants/colors'

const Arch = styled.svg`
  width: 100%;
  height: 80px;
`

const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'flex-start'};
  ${(props) => {
    if (props.hasGradient) {
      return css`
        background-image: linear-gradient(${props.background.join(',')});
      `;
    }
    return css`
      background-color: ${props.background[0]};
    `;
  }}
  user-select: none;
`

export const ArchContent = ({align, background, fontColor, children}) => {
  const hasGradient = background.length > 1
  return (
    <>
      <Arch viewBox="0 0 800 200" preserveAspectRatio="none">
        <path d="M 0 200 C 100 0, 700 0, 800 200 L 0 200" fill={background[0]} />
      </Arch>
      <Content align={align} hasGradient={hasGradient} background={background}>
        {children}
      </Content>
    </>
  )
}