export const frTranslations = {
  Welcome: "Bienvenue.",
  AuthQrCodeDevice: "Veuillez scanner le code QR <0/> <1>d'autorisation </1>pour cet appareil",
  Illustration: "Voici une illustration pour montrer",
  ScannedQrCode: "que vous avez scanné le code QR",
  MoveCamera: "Déplacez lentement la caméra d'avant en arrière et",
  EnsureQrCodeFrame: "assurez-vous que l'intégralité du code QR se trouve dans le cadre",
  Go: "C'est parti.",
  ScanLocation: "Veuillez scanner le code QR <0/> <1>d'emplacement</1> pour commencer.",
  MyActions: "Mes actions",
  Cancel: "Annuler",
  Complete: "Compléter",
  Finish: "Terminer",
  ThankYou: "Merci.",
  ScanNextLocation: "Veuillez scanner le code QR <0/> <1>d'emplacement</1> suivant",
  ScanCode: "Scanner le code QR",
  Exit: "Quitter.",
  AreYouSure: "Etes-vous sûr de vouloir",
  ExitLocation: "quitter cet emplacement ?",
  Yes: "Oui",
  No: "Non",
  Uhoh: "Oh oh !",
  InvalidQrCode: "Ce code QR n'est pas valide",
  TryScanAgain: "Essayez à nouveau de le scanner",
  RescanCode: "Scanner à nouveau le code QR",
  Sorry: "Désolé !",
  NoConnection: "Aucune connectivité Internet",
  Reconnect: "Veuillez vous reconnecter et réessayer",
  Retry: "Réessayer",
  Error: "Erreur…",
  ServerError: "Le serveur a rencontré une erreur.",
  TryAgain: "Veuillez réessayer plus tard",
  UnableConnectFBN: "Impossible de se connecter à FeedbackNow.",
  Ok: "Ok",
  Oops: "Oups...",
  SaveActions: "Je souhaite enregistrer mes actions.",
  Back: "Retour"
}
