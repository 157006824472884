import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Aether, isReady, useMass } from '@forrestertm/newton'
import dayjs from 'dayjs'
import { FullLayout, HorizontalSpacer, Row, VerticalSpacer } from 'components/layouts'
import { getActions, getOrganizations, saveActions } from 'services/rest-service'
import { GLOBAL_MASS, REST_MASS } from 'constants/mass-names'
import { COLORS } from 'constants/colors'
import { Checkbox } from 'components/checkbox'
import { LightButton } from 'components/button'
import actionsIconSrc from 'assets/actions-icon.svg'
import { useTranslation } from 'react-i18next';
import { Header } from 'components/auth/header';
import i18next from 'i18next';
import { Footer } from 'components/Footer';



const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
`


const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`

const BottomContent = styled.div`
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ActionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ActionsLogo = styled.img`
  margin-right: 8px;
  height: 40px;
`

const ActionsHeading = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: #0177D9;
`

const Organizations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const Level = styled.div`
  margin-bottom: 4px;
  color: ${COLORS.font.darkBlue};
  font-size: 16px;
`

const Level1 = styled(Level)`
  font-weight: bold;
`

const Action = styled.div`
  color: ${COLORS.font.darkBlue};
  font-size: 18px;
`

export const ActionsScreen = (props) => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const auth = useMass(REST_MASS.auth)
  const actions = useMass(REST_MASS.actions)
  const locationMap = useMass(GLOBAL_MASS.locationMap)
  const selectedActions = useMass(GLOBAL_MASS.selectedActions)
  const [orgPath, setOrgPath] = useState([])
  const locationId = search.get('locationId')
  const {t} = useTranslation();

  useEffect(() => {
    if (!isReady(locationMap)) {
      getOrganizations(auth.clientId)
    }
    getActions(locationId)
  }, [])

  useEffect(() => {
    if (isReady(actions)) {
      if (selectedActions.locationId !== locationId) {
        Aether.massAction(GLOBAL_MASS.selectedActions, {locationId, selections: new Array(actions.length).fill(false)})
      }
    }
  }, [actions])

  useEffect(() => {
    if (isReady(locationMap)) {
      setOrgPath(locationMap.get(locationId) ?? [])
    }
  }, [locationMap])

  const onActionCheckChanged = (index, checked) => {
    const cloned = {...selectedActions}
    selectedActions.selections[index] = checked
    Aether.massAction(GLOBAL_MASS.selectedActions, cloned)
  }

  const onSave = () => {
    const completedTime = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
    const completedActions = []
    selectedActions.selections.forEach((selection, i) => {
      if (selection) {
        completedActions.push({
          clientId: actions[i].clientId,
          activityId: actions[i].id,
          metadata: actions[i].metadata ?? {},
          locationId,
          completedTime
        })
      }
    })
    saveActions(completedActions)
    Aether.massAction(REST_MASS, null)
    Aether.massAction(GLOBAL_MASS.selectedActions, {})
    navigate(`/thankyou`)
  }

  const onCancel = () => {
    navigate(`/exit?prev=${locationId}`)
  }

  if (!isReady(locationMap) || !isReady(actions)) {
    return null
  }

  const noneSelected = !selectedActions.selections?.some(s => !!s)

  const getActionName = (action) => {
    const {metadata} = action;
    let name = action.name;
    if (metadata && ('name' in metadata) && (i18next.language in metadata.name)) {
      name = action.metadata.name[i18next.language];
    }
    return name;
  }

  return (
    <FullLayout showLogo>
      <Container>
        <TopContent>
          <VerticalSpacer>
            <ActionsHeader>
              <ActionsLogo src={actionsIconSrc}/>
              <ActionsHeading>{t('MyActions')}</ActionsHeading>
            </ActionsHeader>
          </VerticalSpacer>
          <VerticalSpacer>
            <Organizations>
              <Level>{orgPath[1]}</Level>
              <Level>{orgPath[2]}</Level>
              <Level1>{orgPath[3]}</Level1>
            </Organizations>
          </VerticalSpacer>
          <VerticalSpacer>
            {actions.map((action, index) => (
              <Row key={index} marginBottom={16}>
                <HorizontalSpacer margin={10}>
                  <Checkbox checked={selectedActions.selections && selectedActions.selections[index]}
                            onChange={(checked, ev) => onActionCheckChanged(index, checked)}/>
                </HorizontalSpacer>
                <HorizontalSpacer>
                  <Action>{getActionName(action)}</Action>
                </HorizontalSpacer>
              </Row>
            ))}
          </VerticalSpacer>
        </TopContent>
        <BottomContent>
          <VerticalSpacer>
            <Row>
              <HorizontalSpacer>
                <LightButton onClick={onCancel}>{t('Cancel')}</LightButton>
              </HorizontalSpacer>
              <HorizontalSpacer>
                <LightButton disabled={noneSelected} onClick={onSave}>{t('Complete')}</LightButton>
              </HorizontalSpacer>
            </Row>
          </VerticalSpacer>
        </BottomContent>
      </Container>
    </FullLayout>
  )
}
