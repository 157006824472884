import React from "react";
import styled from "styled-components";
import { useLogo } from "utils/hook";

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 80px 0 20px;
`;

const Logo = styled.img`
  height: auto;
  max-height: 60px;
  max-width: 80%;
`;

export const TopLogo = () => {

  const clientLogoSrc = useLogo()
  
  return (
    <Layout>
      <Logo src={clientLogoSrc} />
    </Layout>
  );
};
